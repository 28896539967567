import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import './Voteimpactbar.css';
import bull from '../../../assets/svg/bull.svg';
import bear from '../../../assets/svg/bear.svg';
import { VoteResultProps } from '../../../common/models/Vote';
import { Coin } from '../../../common/models/Coin';
// import { VoteEndCoinPriceContext } from 'Contexts/VoteEndCoinPrice';
// import CoinsContext from 'Contexts/CoinsContext';
// import { VoteDispatchContext } from 'Contexts/VoteProvider';
import { decimal } from "../../Profile/utils";
import BullBearButton from './BullBearButton';



const Voteimpactbar = (
    {
        // lastTenSec,
        vote,
        coins,
        symbol1,
        symbol2,
        voteDirection,
        setImpactValue,
        startprice,
      }: {
        // lastTenSec?: any
        vote?: VoteResultProps;
        coins?: any;
        activeTime?:any;
        symbol1?: string;
          symbol2?: string;
          voteDirection?: any;
          setImpactValue?: any;
          startprice?: any;
      }
) => {
    console.log(voteDirection,"voteDirection")
    const totalBoxes = 10;    
    const [value, setValue] = useState(1);
  const [currentPrice, setCurrentPrice] = useState<any>(0)
  const prevCountRef = useRef(value)
  const [widthValue, setWidthValue] = useState(0);
    const [widthValue2, setWidthValue2] = useState(0);
  // console.log(voteDirection, "voteDirection")
  
  const getCoinValue = () => {
    let tempNewValue = parseFloat(coins) * 100;
    let tempOldValue = parseFloat(startprice || coins ) * 100;
        
    if (tempNewValue === tempOldValue) {
      
      setValue(50);
    } else if (voteDirection == 1) {
      const temp = (tempNewValue - tempOldValue) * 1;
      setValue(50 + temp > 100 ? 100 : 50 + temp < 0 ? 0 : 50 + temp);
    }
    else {
      const temp = (tempOldValue - tempNewValue) * 1;
      setValue(50 + temp > 100 ? 100 : 50 + temp < 0 ? 0 : 50 + temp);
    }
    setCurrentPrice(coins)
}

  useEffect(() => {    
    prevCountRef.current = currentPrice;
    getCoinValue()
  }, [coins]);
    
  const [persentValue, setPersentValue] = useReducer((state: number, action: number) => {
    if (action > 100) {
      return 100;
    }
    return action < 0 ? 0 : action;
  }, 50)



  useEffect(() => {                
    GetWidthValue()
}, [    
    vote,
    coins,
    symbol1,
    symbol2,
    currentPrice.current,
]);
console.log(symbol1,
    symbol2, "symbol1symbol2")


const GetWidthValue = () => {
    // check for mid first
    if (value > 39 && value < 61) {
        setWidthValue(voteDirection == 1 ? Math.floor(Math.random() * (13 - 5 + 1)) + 5 :0)
        setWidthValue2(voteDirection == 2 ? Math.floor(Math.random() * (13 - 5 + 1)) + 5 : 0)
        return
    }
    // check for bull direction    
    if (voteDirection == 1) {        
        if (value > 60) {
            setWidthValue(value)
            setWidthValue2(0)
        }
        else if (value < 40) {
            setWidthValue(0)
            setWidthValue2(100 - value)
        }
        return
    }
    // check for bear direction
    if (voteDirection == 2) {
        console.log(voteDirection,value,"valuevaluevaluevalue")
        if (value > 60) {
            setWidthValue(0)
            setWidthValue2(value)
        }
        else if (value < 40) {
            setWidthValue(100 - value)
            setWidthValue2(0)
        }
        return
    }


}

console.log(value,"valuevalue")
    return (
        <div className="containerValue">
            <div className='Fullbarimpact'>
                {/* Left side (Negative side) */}
                <div className="boxes"
                    style={{
                        // padding: "10px 0px 10px 8px",
                        left:"10px",
                        borderRadius:"10px 0px 0px 10px",
                    }}
                >                    
                    <div className='backGroundBox'
                        // style={{
                        //     
                        // }}  
                        style={{                            
                            background: `${voteDirection == 1 ? "linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)" :"linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)"}`,                             
                        }}    
                    >                        
                    {Array.from({ length: totalBoxes }).map((_, index) => {                        
                        return (
                            <div key={index} className="boxchild"
                                style={{
                                    borderRadius: `${index === 0 ? "10px 0px 0px 10px" : "0px"}`,                                    
                                    // background: index < leftSideFilled ? gradientColor :
                                    //     isPartiallyFilled ? `linear-gradient(to right, ${gradientColor} ${leftSidePartial * 100}%, #fff ${leftSidePartial * 100}%)` : '#fff',
                                }}
                            ></div>
                        );
                    })}
                        <div className='whiteBox'
                            style={{
                                // width: `${persentValue}%`,
                                width: `${100 - widthValue}%`,
                                transition: "width 0.8s ease-in-out",
                            }}
                        >
                        </div>
                </div>
                </div>

                {/* Middle section with bull, vs, and bear */}
                <div className="centerBox">                    
                    <BullBearButton                        
                    voteDirection={voteDirection}
                    vote={vote}
                    coins={coins}
                    symbol1={"BULL"}
                        symbol2={"BEAR"}
                    />
                </div>

                {/* Right side (Positive side) */}
                <div className="boxes"
                    style={{   
                        right: "10px",
                        borderRadius: `0px 10px 10px 0px`,
                    }}
                >
                    <div className='backGroundBox2'
                        style={{
                            background: `${voteDirection == 2 ? "linear-gradient(90deg, rgba(171, 148, 255, 1) 0%, rgba(79, 69, 179, 1) 70%, rgba(9, 9, 121, 1) 100%)" :"linear-gradient(273deg, rgba(171,148,255,1) 0%, rgba(79,69,179,1) 70%, rgba(9,9,121,1) 100%)"}`,
                        }}                            
                    >
                    {Array.from({ length: totalBoxes }).map((_, index) => {                        
                        return (
                            <div key={index} className="boxchild"
                                style={{                                    
                                    borderRadius: `${index === totalBoxes - 1 ? "0px 10px 10px 0px" : "0px"}`, 
                                }}
                            ></div>
                        );
                    })}
                        <div className='whiteBox2'
                            style={{
                                width: `${100-widthValue2}%`,
                                transition:"width 0.8s ease-in-out",
                            }}
                        >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Voteimpactbar;